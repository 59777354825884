import {DOMAIN} from '../../AppConfig';

const PlanConfig = {
    getAllPlan : `${DOMAIN}/listplans/`,
    getPlanById: `${DOMAIN}/plans/`,
    changePlan:`${DOMAIN}/inquiries/plan-update/`,
    addcustomPlan:`${DOMAIN}/inquiries/`,
    payment: `${DOMAIN}/create-payment-link/`,
    payment_failure:`${DOMAIN}/retry-create-product-link/`
}

export default PlanConfig