import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { mobileSpamPatterns } from '../../components/common/common';
import { createContact } from '../../redux/restaurantSlice';
import { ToastContainer, toast } from 'react-toastify';
import LoadingButton1 from '../../components/button/LoadingButton1';
import Address from '../../components/address/Address';
import ReCAPTCHA from 'react-google-recaptcha';

function InquiryFrom() {
  const dispatch = useDispatch();
  const recaptchaRef = useRef();
  const [buttonloading, setButtonloading] = useState(false);
  const [address , setaddress] = useState('');
  const [address_error , setAddressError] = useState();
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [inquiry_data, setInquiry_data] = useState({
    name: '',
    email: '',
    phone_number: '',
    restaurant_name: '',
    number_of_outlets: '',
    source: 'Reach Out Now',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone_number: '',
    restaurant_name: '',
    number_of_outlets: '',

  });

  const handleOnChange = (event) => {
    const fieldName = event.target.name;
    let fieldValue = event.target.value;

    // Rest Name Valiation...
    if (fieldName === 'restaurant_name' && fieldValue.length < 5) {
      setErrors({
        ...errors,
        restaurant_name: 'Restaurant name must be at least 5 characters long.',
      });
    } else if (fieldName === 'restaurant_address') {
      if (fieldValue.length === 0) {
        setErrors({
          ...errors,
          restaurant_address: 'Restaurant address is required',
        });
      } else {
        setErrors({
          ...errors,
          restaurant_address: '',
        });
      }
    } else if (fieldName === 'name') {
      const nameRege = /^[A-Za-z\s]+$/;
      if (fieldValue.length < 1) {
        setErrors({
          ...errors,
          name: 'Name field is required',
        });
      } else if (!nameRege.test(fieldValue)) {
        setErrors({
          ...errors,
          name: 'Name should not contain numbers.',
        });
      } else {
        setErrors({
          ...errors,
          name: '',
        });
      }
    } else if (fieldName === 'email') {
      const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
      if (fieldValue.length === 0) {
        setErrors({
          ...errors,
          email: 'Email is required',
        });
      } else if (!emailRegex.test(fieldValue)) {
        setErrors({
          ...errors,
          email: 'Email is not valid',
        });
      } else {
        setErrors({
          ...errors,
          email: '',
        });
      }
    } else if (fieldName === 'phone_number') {
      fieldValue = fieldValue.replace(/\D/g, '');
      const phoneRegex = /^\d{10}$/;
      if (fieldValue.length > 10) {
        fieldValue = fieldValue.slice(0, 10);
      } else if (fieldValue.length === 0) {
        setErrors({
          ...errors,
          phone_number: 'Phone number is required',
        });
      } else if (fieldValue.length < 10) {
        setErrors({
          ...errors,
          phone_number: 'Phone Number must have maximum 10 digits',
        });
      } else if (!phoneRegex.test(fieldValue)) {
        setErrors({
          ...errors,
          phone_number: 'Phone number is not valid',
        });
      }
      else if (mobileSpamPatterns.includes(fieldValue.toLowerCase())) {
        setErrors({
          ...errors,
          phone_number: 'Please enter a valid phone number',
        });
      }
      else {
        setErrors({
          ...errors,
          phone_number: '',
        });
      }
    } else if (fieldName === 'number_of_outlets') {
      const numberRegex = /^[1-9][0-9]*$/;
      if (fieldValue.length === 0) {
        setErrors({
          ...errors,
          number_of_outlets: 'Number of location is required',
        });
      }
      else if (!numberRegex.test(fieldValue)) {
        fieldValue = '';
      }
      else {
        setErrors({
          ...errors,
          number_of_outlets: '',
        });
      }
    } else {
      setErrors({
        ...errors,
        [fieldName]: '',
      });
    }

    setInquiry_data((prevData) => ({
      ...prevData,
      [fieldName]: fieldValue,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setButtonloading(true)
    const final_data = {
      ...inquiry_data,
      restaurant_address:address
    }
    recaptchaRef.current.reset();
    setRecaptchaToken(null);
    dispatch(createContact(final_data))
      .then((response) => {
        setButtonloading(false)
        if (response.payload.status === 201) {
          toast.success("Your request has been sent successfully. Management will contact you soon.", {
            position: 'top-right',
            autoClose: 1000,
            closeButton: false,
          });
          setInquiry_data({
            name: '',
            email: '',
            phone_number: '',
            restaurant_name: '',
            number_of_outlets: '',
            source: 'Reach Out Now',
          });
          setaddress('');
        } else if (response.payload.status === 400) {
          setButtonloading(false)
          toast.error('The email already exists', {
            position: 'top-right',
            autoClose: 1000,
            closeButton: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const disabled =
    errors.restaurant_name ||
    errors.phone_number ||
    errors.email ||
    address_error ||
    inquiry_data.email.trim() === '' ||
    inquiry_data.restaurant_name.trim() === '' ||
    inquiry_data.phone_number.trim() === '' ||
    inquiry_data.name === '' ||
    inquiry_data.number_of_outlets.trim() === '' ||
    !recaptchaToken;

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ p: 4, borderRadius: 4, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
      <ToastContainer
        position='top-right'
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={true}
        closeButton={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <Typography sx={{ color: 'black', marginBottom: '1.5rem' }} variant='h5' fontWeight={600} >Reach out now</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name='name'
            variant="standard"
            size="small"
            label="Name"
            className='inquiry_field'
            value={inquiry_data.name}
            required
            onChange={handleOnChange}
            helperText={errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name='restaurant_name'
            variant="standard"
            size="small"
            label="Restaurant Name"
            className='inquiry_field'
            value={inquiry_data.restaurant_name}
            required
            onChange={handleOnChange}
            helperText={errors.restaurant_name}
          />
        </Grid>
        <Grid item xs={12} sm={12} className='googel-location'>
          <Address
            setaddress={setaddress}
            setAddressError={setAddressError}
            setRestData={setInquiry_data}
            address={address}
            variant='standard'
            className='inquiry_field'
            helperText={address_error}
            size="small"
            label="Restaurant Address"
            required = {true}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            name='email'
            variant="standard"
            size="small"
            label="Email"
            type="email"
            className='inquiry_field'
            value={inquiry_data.email}
            required
            onChange={handleOnChange}
            helperText={errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name='phone_number'
            variant="standard"
            size="small"
            label="Phone Number"
            className='inquiry_field'
            value={inquiry_data.phone_number}
            type="tel"
            required
            onChange={handleOnChange}
            helperText={errors.phone_number}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name='number_of_outlets'
            variant="standard"
            size="small"
            label="Number of Locations"
            className='inquiry_field'
            value={inquiry_data.number_of_outlets}
            required
            onChange={handleOnChange}
            helperText={errors.number_of_outlets}
          />
        </Grid>
        <Grid item xs={12}>
        <div
          style={{
            marginTop: '10px'
          }}
        >
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE}
            onChange={(token) => setRecaptchaToken(token)}
          />
        </div>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton1
            buttonloading={buttonloading}
            size='small'
            sx={{ borderRadius: '5px', paddingX: '10px', fontSize: '1rem' }}
            text='Submit'
            isDisabled={disabled}
            onClick={handleSubmit}
          />
          {/* <Button variant="contained" color="primary" type="submit"
          sx={{
            backgroundColor: '#818cf8',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#818cf8'
            }
          }}
          disabled={disabled}
          onClick={handleSubmit}
        >
          Submit
        </Button> */}
        </Grid>
      </Grid>
    </Box>
  )
}

export default InquiryFrom